<template>
  <div>
    <section>
      <v-container
        style="min-height:80vh"
        class="d-flex justify-center align-center"
      >
        <section class="white--text justify-center align-center">
          <h1 class="text-center mt-16">Investing in Aetera is now live</h1>
          <h2 class="text-center">Get your tokens now below</h2>
          <v-row class="mt-6" justify="center">
            <v-btn x-large color="primary" @click="buyDialog = true"
              >Invest now</v-btn
            >
          </v-row>
          <v-row class="mt-12" justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <v-progress-linear
                rounded
                style="max-width:640px"
                v-model="skill"
                color="primary"
                height="50"
                class="white--text"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ 100 - Math.ceil(value) }}% of Tokens still available</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-col class="d-flex justify-center" cols="12">
              <h3>? tokens still available for purchase</h3>
            </v-col>
          </v-row>
        </section>
      </v-container>
    </section>
    <section>
      <v-container style="min-height:60vh">
        <v-row>
          <v-col class="white--text" cols="12" md="7"
            ><h2>All investments made</h2>
            <p>See all global investments made below</p>
            <br />
            <v-data-table
              headers="headers"
              items="items"
              hide-actions
              class="elevation-1"
              select-all
              pagination.sync="pagination"
              item-key="id"
              loading="true"
              search="search"
            >
            </v-data-table>
          </v-col>

          <v-col class="white--text" cols="12" md="5">
            <h2>Your investments</h2>
            <p>
              See below table for all investments you have made on the Aetera
              platform
            </p>
            <br />
            <v-data-table
              headers="headers"
              items="items"
              hide-actions
              class="elevation-1"
              select-all
              pagination.sync="pagination"
              item-key="id"
              loading="true"
              search="search"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container >
    </section>
    <section>
      <v-container style="min-height:30vh" class="white--text">
        <h2>Your tokens</h2>
        <p>The amount of tokens you have bought to date</p>
        <v-card color="pa-4" style="max-width:512px">
        <h2>You have bought 0 tokens</h2> 
        <h2>You have invested $0</h2> 
          
        </v-card>
      </v-container>
    </section>
    <v-dialog persistent v-model="buyDialog">
      <v-container>
        <v-card class="px-8 pb-4 pt-8" color="rgba(23, 23, 23, 0.9)">
          <h1 class="white--text text-center my-4 hidden-sm-and-down">
            Invest in Aetera Aqua Token
          </h1>
          <h1
            style="font-size:24px"
            class="white--text text-center my-4 hidden-md-and-up"
          >
            Invest in Aetera Aqua Token
          </h1>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-row class="mb-4">
                <v-col cols="12" md="5">
                  <v-card class="text-center justify-center pt-2">
                    <v-img
                      class="mx-auto  px-2"
                      src="@/assets/coins.png"
                      max-height="196px"
                      contain
                    ></v-img>
                    <h2>Buy with Cryptocurrency</h2>
                    <v-btn large class="my-4" color="primary" disabled
                      >Choose</v-btn
                    >
                  </v-card>
                </v-col>
                <v-col
                  class="d-flex align-center justify-center"
                  cols="12"
                  md="2"
                >
                  <h1 class="text-center white--text">OR</h1>
                </v-col>
                <v-col cols="12" md="5">
                  <v-card class="text-center justify-center pt-2">
                    <v-img
                      class="mx-auto px-4"
                      src="@/assets/bankcards.png"
                      max-height="196px"
                      contain
                    ></v-img>
                    <h2>Buy with Card</h2>
                    <v-btn large class="my-4" color="primary" disabled
                      >Choose</v-btn
                    >
                  </v-card>
                </v-col>
                <h3 class="white--text ml-4">
                  Waiting on details to create payment modals
                </h3>
              </v-row>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field label="Password" type="password"></v-text-field>
                <v-text-field
                  label="Confirm Password"
                  type="password"
                ></v-text-field>
                <span class="text-caption grey--text text--darken-1">
                  Please enter a password for your account
                </span>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <div class="pa-4 text-center">
                <v-img
                  class="mb-4"
                  contain
                  height="128"
                  src="https://cdn.vuetifyjs.com/images/logos/v.svg"
                ></v-img>
                <h3 class="text-h6 font-weight-light mb-2">
                  Welcome to Vuetify
                </h3>
                <span class="text-caption grey--text"
                  >Thanks for signing up!</span
                >
              </div>
            </v-window-item>
          </v-window>

          <v-btn
            color="red darken-3"
            class="white--text"
            @click="buyDialog = !buyDialog"
            >Close Dialog</v-btn
          >
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buyDialog: false,
      step: 1,
    };
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sign-up";
        case 2:
          return "Create a password";
        default:
          return "Account created";
      }
    },
  },
};
</script>

<style></style>
