<template>
  <section class="pt-4">
    <v-toolbar class="mt-16" color="grey darken-1">
      <v-container>
        <v-row>
          <v-toolbar-title class="white--text"
            >Investors Dashboard</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-row>
      </v-container>
    </v-toolbar>
  </section>
</template>

<script>
export default {
  data() {
    return {
      learnMoreOne: false,
      learnMoreTwo: false,
      learnMoreThree: false,
      step: 1,
    };
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sign-up";
        case 2:
          return "Create a password";
        default:
          return "Account created";
      }
    },
  },
  methods: {
		logout() {
			this.$store.dispatch('logout')
		}
	}
};
</script>

<style></style>
