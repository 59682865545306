<template>
<div>
<!-- <dash-toolbar></dash-toolbar> -->
<dashboard-main></dashboard-main>
</div>
</template>

<script>
import DashToolbar from "@/components/Dashboard/DashToolbar.vue";
import DashboardMain from "@/components/Dashboard/DashboardMain.vue";

export default {

    components: {
    DashToolbar,
    DashboardMain,
   
  },

}
</script>

<style>

</style>